<template>
    <div class="thanks">
      <div class="section-top">
        <b-container>
          <b-row class="row-thanks">
            <b-col>
              <b-row class=" align-items-center justify-content-start" style="min-height: 20vh;">
            <b-col md="12">
   
              
            </b-col>
          </b-row>
          <b-row class=" text-center align-items-center justify-content-center" style="min-height: 60vh;">
            <b-col md="11">
              <a :href="$router.resolve({ name: 'home'}).href"> <img class="pb-5" id="logo" src="../assets/img/img_logo_white.svg"></a>
              <h1>¡Gracias por compartirnos tus datos!</h1>
              <h3 class="grey">Nos comunicaremos contigo  lo más pronto posible.</h3>
            </b-col>
          </b-row>
          <b-row class=" align-items-end justify-content-end" style="min-height: 13vh;">
            <b-col md="12">
   
              
            </b-col>
          </b-row>
            </b-col>
          </b-row>
  
        </b-container>
      </div>
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'ThanksPage'
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  .container{
    padding-left: 4%;
    padding-right: 4%; 
  }
  .thanks{
    background-image: url('../assets/img/BG.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #FFFF;
  }
  .row-thanks{
    min-height: 100vh;
  }
  #logo{
      width: 300px;
    }
  h1{
      font-weight: 500;
      font-size: 55px;
      line-height: 75px;
      color: #FFFFFF;
      
  }
  h3{
    font-weight: 200;
    font-size: 35px;
  }
  p{
    font-size: 20px;
  }
  .grey{
  color: #FFFDFC80;
  }
  .img-full{
    width: 100%;
  }
  .display-flex{
    display: flex;
  }
   
  #line1{
    width: 1000px;
    height: 6px;
    margin-left: -14vw;
  }
  
  #line2{
    width: 1000px;
    height: 6px;
    margin-left:  23vw;
    overflow-x: hidden;
    transform: rotate(180deg);
  }
  
  
  @media (max-width: 768px) { 
    .container{
      padding-left: 30px;
      padding-right: 30px; 
    }
  
    
    
    h1{
      font-size: 35px;
      line-height: 1.1;
    }
    h3{
      font-size: 30px;
    }
    p{
      font-size: 18px;
    }
    .banner-section{
      margin-bottom: 115px;
      padding-bottom: 770px;
    }
    .logo-wrapper{
      margin-top: 25px;
      margin-bottom: 25px;
    }
    .logo-wrapper img{
      width: 200px;
    }
    .form-wrapper{
      top: 100px;
      width: 90% !important;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 45px 40px;
    }
    .features-section{
      margin-bottom: 50px;
    }
    .card-feature{
      background: #F6F6F8;
      padding: 50px 30px 60px 30px;
      border-radius: 10px;
      height: 100%;
    }
    .card-feature img{
      margin-bottom: 30px;
    }
    .card-feature p{
      font-size: 18px;
    }
  
    .mid-banner-section{
      padding: 50px 20px;
      width: calc(100% - 3px);
      margin: auto;
      margin-bottom: 70px;
    }
    .col1{
        flex: 12 0 100%;
        margin-bottom: 10px;
        text-align: center;
    }
    .col2{
        flex: 12 0 100%;
        text-align: center;
    }
    
    .percent-wrapper{
      margin-bottom: 70px;
    }
    .percent-wrapper .content-text{
      max-width: 460px;
    }
    .percent-wrapper .content-image{
      padding-right: 4%;
    }
    #order1{
      order: 2;
    }
    #order2{
      order: 1;
    }
  
    .instalation-wrapper{
      width: calc(100% - 3px);
      margin: auto;
      padding: 50px 20px 70px 20px;
      margin-bottom: 50px;
    }
    .instalation-wrapper .inc-red{
      margin-bottom: 32px;
    } 
    .instalation-wrapper p{
      max-width: 850px;
      margin: auto;
      margin-bottom: 45px;
    }
    .footer-wrapper{
      padding-bottom: 100px;
    }
    .btn-footer{
      font-size: 24px;
      padding: 22px;
      margin-top: 20px;
    }
  
    #logo{
      width: 200px;
    }
  }
  </style>
  