<template>
    <div>
        <!--MENU-->
    
         
    <MenuSection :color="color" :logo="logo" :key="color" :active-route="activeRoute"
         @update-active-route="setActiveRoute" @update-language="setActiveLanguage" :active-language="activeLanguage"></MenuSection>
         <b-container id="priv">
      <div id="privacy-notice" class="container mt-5">
        <h1>{{ translations.privacy_notice.title }}</h1>
        <div v-for="(section, index) in translations.privacy_notice.sections" :key="index">
          <h3>{{ section.heading }}</h3>
          <p v-for="(paragraph, pIndex) in section.paragraphs" :key="pIndex">{{ paragraph }}</p>
        </div>
        <p style="text-align: right;"><strong>{{ translations.privacy_notice.last_updated }}</strong></p>
      </div>
    </b-container>
 
    </div>

</template>
 
 
  
<script>
import MenuSection from "./MenuSection.vue";
import esp from '../assets/json/es.json';
import en from '../assets/json/en.json';

export default {
    components: {
        MenuSection
    },
    data() {
        return {
            color: 'light',
            logo: require('../assets/img//img_logo_color.svg'),
            activeRoute: localStorage.getItem('activeRoute') || 'home',
            activeLanguage: localStorage.getItem('selectedLanguage') || 'eng',
            translations: localStorage.getItem('selectedLanguage') === 'esp' ? esp : en,
 
        };
    },
    methods: {
        setActiveRoute(route) {
            this.activeRoute = route;
            localStorage.setItem('activeRoute', route);
        },
        setActiveLanguage(language) {
            this.activeLanguage = language;
            this.translations = language === "eng" ? en : esp;
            localStorage.setItem('selectedLanguage', language);
        } 
    },
    mounted() {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            this.setActiveLanguage(savedLanguage);
        }
    }
};
</script>

<style scoped>

h1{
    font-size: 60px;
    font-weight: 600;
    line-height: 70px;
    text-align: left;
}
#priv{
    padding-top: 8%;
}
h3{
  font-size: 35px;
  font-weight: 600;
  line-height: 47.81px;
}
p{
 
  font-size: 23px;
  font-weight: 400;
  line-height: 31.42px;
}

@media(max-width:768px){
    p{
font-size: 16px;
line-height: 21.32px;
}
h3  {
 
 font-size: 25px;
 line-height: 30px;
     }

     #priv{
    padding-top: 15%;
}
}
</style>