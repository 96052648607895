<template>
    <div id="contacto">
        <img src="../assets/img//img_contact_bg.png" class="img-contacto">
        <b-container> 
            <b-row>
                <b-col>
                    <h2>{{ translations.contact.title }}</h2>
                </b-col>
            </b-row>
            <b-row class="reverse">
                <b-col lg="5" md="12" class="top2" >
                    <span class="espacio">  Tijuana</span>
                    <a href="tel:+526646342930" target="_blank"> <p><img src="../assets/img//phone.svg" style="margin-right: 10px;">(664) 634 2930 / (664) 634 2815</p></a>
                    <a href="mailto:contactotij@focus.com.mx" target="_blank"> <p><img src="../assets/img//mail.svg" style="margin-right: 10px;">contactotij@focus.com.mx</p></a>
                    <a href="https://maps.app.goo.gl/xXDWwPygY71ABuBLA" target="_blank"> <p><img src="../assets/img//send.svg" style="margin-right: 10px;">Santa María #2841 Col. América <br> Tijuana B.C. 22044</p></a>
                </b-col>
                <b-col lg="7" class="compu">
                    <b-form>
                        <b-form-group>
                            <div style="display: none;">
                            <b-form-input
                                id="honeypot"
                                v-model="honeypot"
                                placeholder=" "
                            ></b-form-input>
                            </div>
                        </b-form-group>
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-1">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-1"
                                        v-model="nombre"
                                        placeholder=" "
                                        required
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.name }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group id="input-group-2">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-2"
                                        v-model="cargo"
                                        placeholder=" "
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.position }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                         
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-3">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-3"
                                        v-model="empresa"
                                        placeholder=" "
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.company }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group id="input-group-4">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-4"
                                        v-model="ubicacion"
                                        placeholder=" "
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.location }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-5">
                                    <div class="form-floating">  
                                        <b-form-input
                                            id="input-5"
                                            v-model="correo"
                                            type="email"
                                            placeholder=" "
                                            required
                                        ></b-form-input>
                                        <label for="floatingInput"> {{ translations.contact.formLabels.email }} </label>
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group id="input-group-6">
                                    <div class="form-floating">  
                                        <b-form-input
                                            id="input-6"
                                            v-model="telefono"
                                            placeholder=" "
                                            required
                                        ></b-form-input>
                                        <label for="floatingInput"> {{ translations.contact.formLabels.phone }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-7">
                                    <div class="form-floating texta"  >
                                        <b-form-textarea 
                                        id="input-7"
                                        v-model="mensaje"
                                        placeholder=" "
                                        rows=3
                                        max-rows=4
                                        
                                        ></b-form-textarea>
                                        <label for="input-5">{{ translations.contact.formLabels.message }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>

 
                        <b-button class="boton enviar btn-inicio" v-on:click="sendMessage">{{ translations.contact.sendMessage }} </b-button>
                    </b-form>   
                </b-col>
                <b-col lg="12" class="telefono top" >
                    <b-form>
                        <b-form-group>
                            <div style="display: none;">
                            <b-form-input
                                id="honeypot"
                                v-model="honeypot"
                                placeholder=" "
                            ></b-form-input>
                            </div>
                        </b-form-group>
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-1">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-1"
                                        v-model="nombre"
                                        placeholder=" "
                                        required
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.name }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row> 
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-6">
                                    <div class="form-floating">  
                                        <b-form-input
                                            id="input-6"
                                            v-model="telefono"
                                            placeholder=" "
                                            required
                                        ></b-form-input>
                                        <label for="floatingInput"> {{ translations.contact.formLabels.phone }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-5">
                                    <div class="form-floating">  
                                        <b-form-input
                                            id="input-5"
                                            v-model="correo"
                                            type="email"
                                            placeholder=" "
                                            required
                                        ></b-form-input>
                                        <label for="floatingInput"> {{ translations.contact.formLabels.email }} </label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col>
                                <b-form-group id="input-group-2">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-2"
                                        v-model="cargo"
                                        placeholder=" "
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.position }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                         
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-3">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-3"
                                        v-model="empresa"
                                        placeholder=" "
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.company }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col>
                                <b-form-group id="input-group-4">
                                    <div class="form-floating align-items-center">  
                                        <b-form-input
                                        id="input-4"
                                        v-model="ubicacion"
                                        placeholder=" "
                                        ></b-form-input>
                                        <label for="floatingInput">{{ translations.contact.formLabels.location }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
 
                        <b-row>
                            <b-col>
                                <b-form-group id="input-group-7">
                                    <div class="form-floating texta"  >
                                        <b-form-textarea 
                                        id="input-7"
                                        v-model="mensaje"
                                        placeholder=" "
                                        rows=2
                                        max-rows=2
                                        
                                        ></b-form-textarea>
                                        <label for="input-5">{{ translations.contact.formLabels.message }}</label>
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>

 
                        <b-button class="boton enviar btn-inicio" v-on:click="sendMessage">{{ translations.contact.sendMessage }} </b-button>
                    </b-form>   
                </b-col>
            </b-row>
        </b-container>
    </div>  

</template>

<script>
import { BForm } from 'bootstrap-vue';
import esp from '../assets/json/es.json';
import en from '../assets/json/en.json';
import axios from 'axios'
export default {
  props: {
    activeLanguage: {
      type: String,
      required: true
    }
  },
  components: {
    BForm
  },
  data() {
    return {
        translations: this.activeLanguage === 'eng' ? en : esp,
    }
  },
  watch: {
    activeLanguage(newLanguage) {
        this.translations = newLanguage === 'eng' ? en : esp;
    }
  },
  methods: {
    async sendMessage() {
    if (this.honeypot) {
      console.log("Honeypot triggered.");
      return;
    }

    if (this.nombre !== '' && this.telefono !== '' && this.correo !== '') {
      try {
        const response = await axios.post('phpmailer.php', {
          nombre: this.nombre,
          cargo: this.cargo,
          empresa: this.empresa,
          ubicacion: this.ubicacion,
          correo: this.correo,
          telefono: this.telefono,
          mensaje: this.mensaje
        });

        console.log(response.data); // Logging the response data

        if (response.data == 200 || response.data == '200') {
            if (this.activeLanguage === 'esp') {
            this.$router.push('/gracias');
          } else {
            this.$router.push('/thankyou');
          }
        } else {
            this.messageSend = 'Error al enviar mensaje, intenta más tarde.';
             alert('Error al enviar mensaje, intenta más tarde.');
        }
      } catch (error) {
        console.error(error);
        this.messageSend = 'Error al enviar mensaje, intenta más tarde.';
            alert('Error al enviar mensaje, intenta más tarde.');
      }
    } else {
        this.messageSend = 'Ingresa la información necesaria en los campos.';
        alert('Ingresa la información necesaria en los campos.');
    }
  }
  }
}
</script>


<style scoped> 

h2{
  font-size: 45px;
  font-weight: 600;
  line-height: 70px;
  text-align: left;
}

p{
    color: #191334;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
    text-align: left;
    margin-bottom: 0;
}
 
span{
    font-size: 20px;
    font-weight: 700;
    line-height: 46px;
    text-align: left;
    color: #191334;
}
#contacto{
    padding-top: 5%;
    padding-bottom: 5%;
    position: relative;
    z-index: 2;
}

.img-contacto{
    bottom: 0;
    width: auto;
    height: 100%;
    opacity: 0.4;
    position: absolute;
    z-index: -2;

}


.form-control{
    overflow-y: hidden !important;
}
.form-floating > label {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #191334;
   
  }

  .form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after, .form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after{
      background: #FFFFFF80;
      color: #191334;
  }

  .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    opacity: 0.7;
    color: #191334;
    border: none;
  }

  .btn-inicio.b-button:focus,
.btn-inicio.b-button:hover,
.btn-inicio.b-button:active,
.btn-inicio.b-button:visited,
.btn-inicio.b-button:focus-visible,
.btn-inicio.b-button:focus-within,
.btn-inicio.b-button:focus:hover,
.btn-inicio.b-button:focus:active,
.btn-inicio.b-button:hover:focus,
.btn-inicio.b-button:active:focus {
    background-color: #0CCF8D !important;
    border: none !important;
    color: #191334;
    box-shadow: none !important;
    outline: none !important;
    text-decoration: none !important;
    cursor: pointer;
}

.btn-inicio.btn:focus,
.btn-inicio.btn:hover,
.btn-inicio.btn:active,
.btn-inicio.btn:visited,
.btn-inicio.btn:focus-visible,
.btn-inicio.btn:focus-within,
.btn-inicio.btn:focus:hover,
.btn-inicio.btn:focus:active,
.btn-inicio.btn:hover:focus,
.btn-inicio.btn:active:focus {
    background-color: #0CCF8D !important;
    border: none !important;
    color: #191334;
    box-shadow: none !important;
    outline: none !important;
    text-decoration: none !important;
    cursor: pointer;
}
  
.telefono{
    display: none;
}
  input:focus,
  input:active{
      outline: none ;
      margin-bottom: 0px;
      box-shadow: none ;
      border: none;
  }
 
 
  .form-floating{
    position: relative;
    border: none;
    border-radius: 10px;
    background-color:   #FFFFFF80;
 
  }
  .form-floating > .form-control{
    height: 70px;
    font-family: 'Manrope';
    color: #191334;
    border:none;
    background-color: transparent ;
    padding-left: 22px;
    padding-top: 32px;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
  }


  .enviar{
    background-color:#0CCF8D ;
    color: #191334;
    font-size: 22px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    border-radius: 100px;
    border: none;
    padding: 2% 4% 2% 4%;

  }
   
  
@media(max-width:768px){
    h2{
 
font-size: 35px;
line-height: 50px;
    }

    p{
        font-size: 16px;
line-height: 21.32px;
    }
    span{
    font-size: 17px;
 
    line-height: 36px;
 

}

 
  .enviar{
    
    font-size: 16px;
   
    line-height: 20px;
    

  }

  .telefono{
    display: flex;
    width: 100%;
  }

  .compu{
    display: none;
  }




  .form-floating > label {
    position: absolute;
    top: 10px;
    left: 17px;
    font-size: 15px;
 
    line-height: 16px;
 
   
  }
  .img-contacto{
    bottom: 0;
    right: 0;
    width: auto;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    z-index: -2;

}

  .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
    font-size: 14px;
 
    line-height: 16px;
 
  }

  form{
    width: 100%;
  }

  .form-floating > .form-control{
    height: 63px;
    padding-left: 19px;
    padding-top: 32px;
    font-size: 18px;
  }

  #contacto{
    padding-top: 10%;
    padding-bottom: 15%;
 
}

.top2{
    padding-top: 5%;
}

.top{
    padding-top: 5%;
}
}








</style>