<template>
    <div>
        <b-container class="cert">
            <b-row class="justify-content-around align-items-center">
                <b-col lg="4" md="5" sm="8" class="content">
                    <h3>{{ translations.cert.title }}</h3>
                    <p>{{ translations.cert.description }}</p>
                </b-col>
                <div class="w-100"></div>
                <b-col lg="3" md="3" sm="6" class="content2">
                    <img src="../assets/img/img_certificacion1.png" class="resp-img">
                </b-col>
                <b-col lg="3" md="4" sm="6" class="content2">
                    <img src="../assets/img/img_certificacion2.png" class="resp-img">
                </b-col>
            </b-row>
        </b-container>
    </div>


</template>


<script>
 
import esp from '../assets/json/es.json';
import en from '../assets/json/en.json';

export default {
  props: {
    activeLanguage: {
      type: String,
      required: true
    }
  },
 
  data() {
    return {
      translations: this.activeLanguage === 'eng' ? en : esp,
    }
  },
  watch: {
    activeLanguage(newLanguage) {
      this.translations = newLanguage === 'eng' ? en : esp;
    }
  },
 
}
</script>



<style scoped>

.cert{
    border-top: 1px solid #1D34A2;
    border-bottom: 1px solid #1D34A2;
    padding: 3%;
    margin-top: 3%;
}


.resp-img {
  width: 100%;
  height: auto;
  max-width: 290px;
 
}

h3{
  font-size: 35px;
  font-weight: 600;
  line-height: 47.81px;
}
p{
  color:#191334;
  font-size: 23px;
  font-weight: 400;
  line-height: 31.42px;
}
.w-100{
    display: none;
}
@media (max-width: 768px) {
.justify-content-around{
    justify-content: flex-start !important;
}
.w-100{
    display: flex;
}
h3{
 
 font-size: 26px;
  
 line-height: 35.52px;
  
 
 }
 p{
        font-size: 16px;
line-height: 21.32px;
    }

 
 }


 @media (max-width: 570px) {

    .content{
        max-width: 100%;
    }  .content2{
        max-width: 50%;
    }
    .cert{
    max-width: 90%;
    padding: 3%;
    margin-top: 8%;
}
 
 } 
</style>