import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin, CarouselPlugin, FormPlugin,FormTextareaPlugin,ModalPlugin} from 'bootstrap-vue'
import VueRouter from 'vue-router'
import router from './router'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


 
 
 

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(CarouselPlugin)
Vue.use(FormPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(ModalPlugin)
Vue.use(VueRouter)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
