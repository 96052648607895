<template>
    <div id="footer">
      <b-container>
        <b-row>
          <b-col>
            <img id="logo" src="../assets/img//img_logo_white.svg">
          </b-col>
        </b-row>
        <b-row class="compu">
          <b-col class="mt-2">
             
            <a href="https://maps.app.goo.gl/xXDWwPygY71ABuBLA" target="_blank"> <p>Santa María #2841 Col. América  Tijuana B.C. 22044</p></a>
            <a href="tel:+526646342930" target="_blank"> <p>(664) 634 2930 / (664) 634 2815</p></a>
            <a href="mailto:contactotij@focus.com.mx" target="_blank"> <p>contactotij@focus.com.mx</p></a>
          </b-col>
          <b-col class="text-right">
            <a :href="$router.resolve({ name: 'avisoprivacidad' }).href"> <span>{{ translations.footer.privacyNotice }}</span></a>
            <p> {{ translations.footer.rightsReserved }}<br>{{ translations.footer.company }}</p>
          </b-col>
        </b-row>
        <b-row class="telefono">
          <b-col class="mt-2">
             
            <a href="https://maps.app.goo.gl/xXDWwPygY71ABuBLA" target="_blank"> <p>Santa María #2841 Col. América Tijuana B.C. 22044</p></a>
            <a href="tel:+526646342930" target="_blank"> <p>(664) 634 2930 / (664) 634 2815</p></a>
            <a href="mailto:contactotij@focus.com.mx" target="_blank"> <p>contactotij@focus.com.mx</p></a>
            <a :href="$router.resolve({ name: 'avisoprivacidad' }).href"> <span>{{ translations.footer.privacyNotice }}</span></a>
            <p> {{ translations.footer.rightsReserved }}<br>{{ translations.footer.company }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>


<script>
 
import esp from '../assets/json/es.json';
import en from '../assets/json/en.json';

export default {
  props: {
    activeLanguage: {
      type: String,
      required: true
    }
  },
 
  data() {
    return {
      translations: this.activeLanguage === 'eng' ? en : esp,
    }
  },
  watch: {
    activeLanguage(newLanguage) {
      this.translations = newLanguage === 'eng' ? en : esp;
    }
  },
 
}
</script>


<style scoped>

#footer{
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: #070620;
    background-image: url('../assets/img//img_footer_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

p{
    margin-bottom: 0;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
 
}

span {
    color:#0CCF8D;
font-size: 12px;
font-weight: 700;
line-height: 16.39px;
 text-decoration: none;
}
.telefono{
  display: none;
}
@media(max-width:768px){
  .compu{
    display: none;
  }

  .telefono{
    display: flex;
  }
  .mt-2{
    margin-top: 15px !important;
  }
    p, span{
        font-size: 14px;
line-height: 17.32px;
margin-top: 0;

    } #logo{
        width: 167px;
    }
    #footer{
    padding-top: 5%;
    padding-bottom: 5%;
 
}
}
 

</style>