<template>
  <div @scroll="handleScroll">
    <!--MENU-->
 
    <MenuSection :color="color" :logo="logo" :key="color" :active-route="activeRoute"
         @update-active-route="setActiveRoute" @update-language="setActiveLanguage" :active-language="activeLanguage"></MenuSection>

    <!--INTRO-->

    <div id="inicio" class="section dark">
      <b-container>
        <b-row class="align-items-center">
          <b-col lg="6" md="8">
            <h1>{{ translations.intro.title }}</h1>
            <a :href="$router.resolve({ name: 'SolucionesPage' }).href"> <b-button class="btn-inicio" @click="setActiveRoute('SolucionesPage')">{{ translations.intro.button }}</b-button></a>
          </b-col>
          <b-col lg="6" md="4">
              <img class="resp-img" src="../assets/img//img_home1.png"> 
          </b-col>
        </b-row>
      </b-container>
    </div>


    <!--QUIENES SOMOS -->

    <div id="somos" class=" section light">
      <b-container>
        <b-row class="justify-content-center">
          <b-col lg="8" md="12">
            <h3>{{ translations.aboutUs.title }} </h3>
            <p>{{ translations.aboutUs.description }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--COLLAGE-->

    <CollagePage class="section light"  ></CollagePage>

    <!--QUE HACEMOS-->

    <div class=" section light rig" >
      <b-container>
        <b-row class="align-items-center dir">
          <b-col lg="6" md="5">
            <img class="resp-img2" src="../assets/img/img_home2.png">
          </b-col>
          <b-col lg="5" md="7" class="subir">
            <h3>{{ translations.whatWeDo.title }} </h3>
            <p>{{ translations.whatWeDo.description }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>


    <!--SOLUCIONES-->

    <SolucionesSection class="section dark soluciones" :active-language="activeLanguage"></SolucionesSection> 

    <!--CASOS DE EXITO-->

    <div id="casos" class=" section light">
      <b-container>
        <b-row class="justify-content-center text-center pb-4">
          <b-col lg="7">
            <h3>{{ translations.successStories.title }}</h3>
            <p>{{ translations.successStories.description }}</p>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row class="justify-content-center compu"> 
          <b-col lg="11">
            <b-row class="justify-content-center align-items-center ">
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito1.png" class="case-img">
                </b-col>
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito2.png" class="case-img">
                </b-col>
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito3.png" class="case-img">
                </b-col>
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito4.png" class="case-img">
                </b-col>
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito5.png" class="case-img">
                </b-col>
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito6.png" class="case-img">
                </b-col>
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito7.png" class="case-img">
                </b-col>
                <div class="w-100"></div>
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito8.png" class="case-img">
                </b-col>
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito9.png" class="case-img">
                </b-col>
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito10.png" class="case-img">
                </b-col>
                <b-col md="3" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito11.png" class="case-img">
                </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="justify-content-center phone"> 
          <b-col lg="12">
            <b-row class="justify-content-center align-items-center aqui " style="flex-wrap: nowrap;">
                <b-col md="4" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito1.png" class="case-img">
                </b-col>
                <b-col md="4" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito2.png" class="case-img">
                </b-col>
                <b-col md="4" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito3.png" class="case-img">
                </b-col>
            </b-row>
            <b-row class="justify-content-center align-items-center  aqui" style="flex-wrap: nowrap;">


                <b-col md="4" class="text-center mb-4" style="max-width: 33%;">
                    <img src="../assets/img//img_casosexito4.png" class="case-img">
                </b-col>
                <b-col md="4" class="text-center mb-4" style="max-width: 33%;">
                    <img src="../assets/img//img_casosexito5.png" class="case-img">
                </b-col>
              </b-row>   
              <b-row class="justify-content-center align-items-center aqui " style="flex-wrap: nowrap;"> 
                <b-col md="4" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito6.png" class="case-img">
                </b-col>
                <b-col md="4" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito7.png" class="case-img">
                </b-col>
 
                <b-col md="4" class="text-center mb-4">
                    <img src="../assets/img//img_casosexito8.png" class="case-img">
                </b-col>
              </b-row>
              <b-row class="justify-content-center align-items-center aqui " style="flex-wrap: nowrap;"> 
                <b-col md="4" class="text-center mb-4" style="max-width: 33%;"> 
                    <img src="../assets/img//img_casosexito9.png" class="case-img">
                </b-col>
                <b-col md="4" class="text-center mb-4" style="max-width: 33%;">
                    <img src="../assets/img//img_casosexito10.png" class="case-img">
                </b-col>
 
            </b-row>
          </b-col>
        </b-row>
 
      </b-container>
      <CertificacionesSection :active-language="activeLanguage"></CertificacionesSection>
    </div>


    <!--COBERTURA-->

    <div id="cobertura" class="section dark ">
      <b-container>
        <b-row  class="end">
          <b-col lg="5" md="6" sm="5" style="max-width: 75%;" class="width">
            <h4 v-html="translations.coverage.description"></h4>
          </b-col>
        </b-row>
      </b-container>
    </div>


    <!--CONTACTO-->

    <ContactoSection class="section light" :active-language="activeLanguage"></ContactoSection>

    <!--FOOTER-->

    <FooterSection :active-language="activeLanguage"></FooterSection>
    
   </div>
</template>

 
 
<script>
import CollagePage from "./CollagePage.vue"
import SolucionesSection from "./SolucionesSection.vue"
import ContactoSection from "./ContactoSection.vue"
import FooterSection from "./FooterSection.vue"
import MenuSection from "./MenuSection.vue"
import CertificacionesSection from "./CertificacionesSection.vue"
import esp from '../assets/json/es.json';
import en from '../assets/json/en.json';
export default {
    components: {
        CollagePage, SolucionesSection, ContactoSection, FooterSection, MenuSection,CertificacionesSection
    },
    data() {
        return {
            color: 'dark',
            logo: require('../assets/img//img_logo_white.svg'),
            activeRoute: localStorage.getItem('activeRoute') || 'home',
            activeLanguage: localStorage.getItem("selectedLanguage") || "eng",
            translations: localStorage.getItem("selectedLanguage") === "esp" ? esp : en 
            
        }
    },  watch: {
    activeLanguage(newLanguage) {
      this.translations = newLanguage === "eng" ? en : esp;
    },
  },
    mounted() {
      const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
          this.setActiveLanguage(savedLanguage);
        } else {
          this.setActiveLanguage('eng');
    }
    window.addEventListener('scroll', this.handleScroll);
    },
    methods: {setActiveRoute(route) {
    console.log('Updating active route to:', route);
    this.activeRoute = route;
    localStorage.setItem('activeRoute', route);
  },
 
        toggleColor(section) {
            if (section.classList.contains('dark')) {
                this.color = 'dark';
                this.logo = require('../assets/img//img_logo_white.svg');
            } else {
                this.color = 'light';
                this.logo = require('../assets/img//img_logo_color.svg');
            }
        },
        handleScroll() {
      const sections = document.querySelectorAll('.section');
      let currentSection = null;

      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 50 && rect.bottom >= 50) {
          currentSection = section;
        }
      });

      if (currentSection) {
        this.toggleColor(currentSection);
      }
    },
        
        
        setActiveLanguage(language) {
      this.activeLanguage = language;
      this.translations = language === "eng" ? en : esp;
        localStorage.setItem('selectedLanguage', language);
    }
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>
<style scoped>

 

/*  INTRO  */

h1{
  font-size: 65px;
  font-weight: 600;
  line-height: 65px;
  text-align: left;
  color: #FFFFFF;

}

h3{
  font-size: 35px;
  font-weight: 600;
  line-height: 47.81px;
}
p{
  color:#191334;
  font-size: 23px;
  font-weight: 400;
  line-height: 31.42px;
}
h4{
  color:#FFFFFF;
  font-size: 30px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;

}
 
 
.resp-img {
  width: 130%;
  height: auto;
  max-width: 130%;
  margin-left: -14%;
}
.resp-img2 {
  width: 130%;
  height: auto;
  max-width: 130%;
  margin-left: -14%;
}
 
.phone{
  display: none;
}
#inicio{
  padding-top: 3%;
  padding-bottom: 5%;
  background-color: #1D34A2;
  background-image: url('../assets/img//home_bg.png');
  background-repeat: no-repeat;
  background-size: 34%;
  background-position: bottom right;
}

.btn-inicio{
  padding: 2% 4% 2% 4%;
  margin-top: 4%;
  font-size: 20px;
  font-weight: 400;
  line-height: 27.32px;
  text-align: left;
  border-radius: 100px;
  background-color: #0CCF8D;
  color: #191334;
  border: none;
}
 
 
.btn-inicio.b-button:focus,
.btn-inicio.b-button:hover,
.btn-inicio.b-button:active,
.btn-inicio.b-button:visited,
.btn-inicio.b-button:focus-visible,
.btn-inicio.b-button:focus-within,
.btn-inicio.b-button:focus:hover,
.btn-inicio.b-button:focus:active,
.btn-inicio.b-button:hover:focus,
.btn-inicio.b-button:active:focus {
    background-color: #0CCF8D !important;
    border: none !important;
    color: #191334;
    box-shadow: none !important;
    outline: none !important;
    text-decoration: none !important;
    cursor: pointer;
}

.btn-inicio.btn:focus,
.btn-inicio.btn:hover,
.btn-inicio.btn:active,
.btn-inicio.btn:visited,
.btn-inicio.btn:focus-visible,
.btn-inicio.btn:focus-within,
.btn-inicio.btn:focus:hover,
.btn-inicio.btn:focus:active,
.btn-inicio.btn:hover:focus,
.btn-inicio.btn:active:focus {
    background-color: #0CCF8D !important;
    border: none !important;
    color: #191334;
    box-shadow: none !important;
    outline: none !important;
    text-decoration: none !important;
    cursor: pointer;
}

/*  QUIENES SOMOS  */

#somos{
  padding-top: 10%;
  text-align: center;
  padding-bottom: 4%;
}
 

#casos{
  padding-top: 5%;
  padding-bottom: 5%;
}

.case-img{
  height: auto;
  width: 100%;
}
 
/*  COBERTURA  */


#cobertura {
  padding-top: 17%;
  padding-bottom: 17%;
  background-color: #1D34A2;
  background-image: url('../assets/img//img_map.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
}
 

@media (max-width: 1200px) {

  #inicio{
    padding-top: 15%;
    padding-bottom: 10%;
  }
}

@media(max-width:990px){
  .resp-img {
  width: 270%;
  height: auto;
  max-width: 300%;
  margin-left: -46%;
  margin-top: -40%;
}  
.resp-img2 {
  width: 220%;
  height: auto;
  max-width: 300%;
  margin-left: -70%;
}

#cobertura{
  padding-top: 7%;
  padding-bottom: 7%;
  background-size: 50%;
  background-size: contain;
}

.rig{
  margin-right: -18%;
}


}

@media (max-width: 768px) {

#inicio{
  padding-top: 24%;
  padding-bottom: 10%;
}
.btn-inicio{
  font-size: 16px;
  line-height: 24.32px;
}
h3{
 
 font-size: 26px;
  
 line-height: 35.52px;
  
 
 }

 #inicio{
  background-size: 60%;
 }

 h4{
 
 font-size: 21px;
  
 line-height: 22.52px;
  
 
 }

 .aqui .text-center{
      margin-bottom: 7px !important;
    }
 p{
        font-size: 16px;
line-height: 21.32px;
    }


 

    .resp-img  {
        width: 80%;
        height: auto;
        max-width: 300%;
        margin-left: 33%;
         
        margin-top: -13%;
    }
    .rig  {
        margin-right: 0;
    }
.resp-img2 {
 
  height: auto;
  width: 80%;
  max-width: 100%;
  margin-left: 10%;
  margin-top: -10%;
}

 

.soluciones{
  margin-top: -10%;

}

#casos{
  padding-top: 10%;
  padding-bottom: 10%;
}

.compu{
  display: none;
}

.phone{
  display: flex;
}

.aqui .text-center{
  padding-left: 5px !important;
  padding-right: 5px !important;
}

#cobertura{
  padding-top: 29%;
  padding-bottom: 29%;
  background-position: left   230px bottom 50%;
  background-size: cover;
}

h4{
  text-align: left;
}
.end{
  justify-content: flex-start;
}

.subir{
  margin-top: -15%;
  padding-bottom: 25%
}
}


@media (max-width:575px){

.width{
  width: 65% !important;
}
}
@media (max-width: 500px) {

#inicio{
  padding-top: 35%;
  padding-bottom: 10%;
}

.resp-img {
  width: 100%;
  height: auto;
  max-width: 300%;
  margin-left: 0;
  margin-top: 0;
} 

.resp-img2  {
        height: auto;
        max-width: 159%;
        width: 130%;
        margin-left: -11%;
        margin-top: -8%;
}

.soluciones{
  margin-top: -10%;

}

#casos{
  padding-top: 10%;
  padding-bottom: 10%;
}

.compu{
  display: none;
}

.phone{
  display: flex;
}

.aqui .text-center{
  padding-left: 5px !important;
  padding-right: 5px !important;
}

#cobertura{
  padding-top: 12%;
  padding-bottom: 12%;
  background-position: left   220px bottom 50%;
  background-size: 100%;
}

.subir{
  margin-top: -25%;
  padding-bottom: 20%
}

h4{
  text-align: left;
}
.end{
  justify-content: flex-start;
}
 

}
</style>
