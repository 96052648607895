import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '../components/HelloWorld'
import AvisoPrivacidad from '../components/AvisoPrivacidad'
import ThanksPage from '../components/ThanksPage.vue'
import NosotrosPage from '../components/NosotrosPage.vue'
import SolucionesPage from '../components/SolucionesPage.vue'
import ContactoPage from '../components/ContactoPage.vue'
import ThanksEng from '../components/ThanksEng.vue'
Vue.use(Router)

 
export default new Router({
  mode: 'history',
 
  routes: [
    {
        path: '/',
        name: 'home',
        component: HelloWorld 
    },
    {
      path: '/avisoprivacidad',
      name: 'avisoprivacidad',
      component: AvisoPrivacidad
    },
     {
  path: '/gracias',
  name: 'ThanksPage',
  component: ThanksPage
},     {
  path: '/thankyou',
  name: 'ThanksEng',
  component: ThanksEng
},
{
  path: '/nosotros',
  name: 'NosotrosPage',
  component: NosotrosPage
},
{
  path: '/soluciones',
  name: 'SolucionesPage',
  component: SolucionesPage
},{
    path: '/contacto',
    name: 'ContactoPage',
    component: ContactoPage
  } 
  ]
})

